import { cn } from "@/lib/utils";
import { Metadata } from "@/pages/Reporting/Report";

import { Block } from "./Block";
import { ContentData } from "./Report.utils";
import { TextContent } from "./TextContent";

export const Report = ({
  title,
  content,
  onUpdateContentList,
  onUpdateTitle,
  editable,
}: {
  title: ContentData;
  content: ContentData[];
  onUpdateContentList: (contents: ContentData[]) => void;
  onUpdateTitle: (contents: ContentData) => void;
  editable: boolean;
}) => {
  return (
    <>
      <div id="Title" className={cn("fcol ml-3", "gap-4", editable && "hover:bg-deepBg", "p-4", "rounded-md")}>
        <TextContent content={title} parentEditMode={editable} onContentUpdate={onUpdateTitle}></TextContent>
      </div>
      <div className="px-2">
        <Block onUpdateContent={onUpdateContentList} editable={editable} contents={content} />
      </div>
    </>
  );
};
