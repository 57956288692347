import Plotly from "plotly.js-dist-min";

import { TimeSeriesResult } from "@/apis/nannyml";
import { getResultTitles } from "@/formatters/monitoring";

import { ResultPlot } from "../monitoring";
import { ContentData, getPlotData, plotGreyGrid, plotGreyLabel } from "./Report.utils";
import { TextContent } from "./TextContent";

const PlotContent = ({ content }: { content: ContentData }) => {
  const results = getPlotData(content.plotOptions!);

  const onUpdate = (figure: any, graphDiv: any) => {
    Plotly.toImage(graphDiv).then(function (url) {
      content.plotOptions!.plotImage = url;
    });
    // This is intended for individual plot zooming
    content.plotOptions!.layout = figure.layout;
  };

  if (results) {
    const titles = getResultTitles({ ...results[0] } as TimeSeriesResult);
    content.plotOptions!.plotTitle = `${titles.title} ${titles.subtitle}`;
  }

  // TODO: Remove styling override once plot component supports theming
  return (
    <>
      {results ? (
        <ResultPlot
          results={[results[0]]}
          onPlotUpdate={onUpdate}
          layout={{
            ...content.plotOptions!.layout,
            font: { color: plotGreyLabel },
            xaxis: {
              ...content.plotOptions!.layout?.xaxis,
              gridcolor: plotGreyGrid,
              griddash: "dash",
              linecolor: plotGreyGrid,
            },
            yaxis: {
              ...content.plotOptions!.layout?.yaxis,
              gridcolor: plotGreyGrid,
              griddash: "dash",
              linecolor: plotGreyGrid,
            },
          }}
        />
      ) : null}
    </>
  );
};

export const Content = ({
  content,
  onContentUpdate,
  editMode,
}: {
  content: ContentData;
  onContentUpdate: (content: ContentData) => void;
  editMode: boolean;
}) => {
  return content.plotOptions ? (
    <>
      <PlotContent content={content} />
    </>
  ) : (
    <>
      <TextContent parentEditMode={editMode} content={content} onContentUpdate={onContentUpdate} />
    </>
  );
};
