import pptxgen from "pptxgenjs";

import { ContentData, Theme } from "./Report.utils";

type Slide = {
  contentData: ContentData;
  header: string;
};

const logoSlideOptions = {
  x: "90%",
  y: "5%",
  h: "10%",
  w: "7%",
} as pptxgen.TextPropsOptions;

const headerSlideOptions = {
  align: "left",
  x: "10%",
  y: "5%",
  w: "80%",
  h: "10%",
  fontSize: 20,
  bold: true,
} as pptxgen.TextPropsOptions;

const textSlideOptions = {
  x: "10%",
  y: "30%",
  w: "70%",
  h: "50%",
  fontSize: 20,
} as pptxgen.TextPropsOptions;

const imageLabelSlideOptions = { x: "10%", y: "30%", fontSize: 15 } as pptxgen.TextPropsOptions;
const imagePositionSlideOptions = { x: "10%", y: "35%", w: "80%", h: "50%" } as pptxgen.TextPropsOptions;

export const PptxReport = (contents: ContentData[], theme: Theme) => {
  const [titleSlide, ...slideContents] = contents;

  const presentationContent = slideContents.reduce(
    (acc, content) => {
      if (content.label === "Header") {
        acc.currentHeader = content.data!;

        return acc;
      }

      acc.content.push({ contentData: content, header: acc.currentHeader });

      return acc;
    },
    { content: [] as Slide[], currentHeader: "" }
  );

  let pres = new pptxgen();
  const headerSlide = pres.addSlide();

  headerSlide.background = { color: theme.backgroundColor };

  headerSlide.addText(titleSlide!.data!, {
    shape: pres.ShapeType.rect,
    align: "center",
    x: "10%",
    y: "35%",
    w: "80%",
    fontSize: 40,
    bold: true,
    fontFace: theme.font,
    color: theme.color,
  });
  headerSlide.addImage({ path: "/nannyml-logo-icon.png", ...logoSlideOptions });

  presentationContent.content.map((content) => {
    let slide = pres.addSlide();

    slide.background = { color: theme.backgroundColor };

    slide.addText(content.header, {
      shape: pres.ShapeType.rect,
      ...headerSlideOptions,
      fontFace: theme.font,
      color: theme.color,
    });
    slide.addImage({ path: "/nannyml-logo-icon.png", ...logoSlideOptions });

    if (content.contentData.label === "Text") {
      slide.addText(content.contentData.data!, { ...textSlideOptions, fontFace: theme.font, color: theme.color });
    } else {
      slide.addText(content.contentData.plotOptions!.plotTitle!, { ...imageLabelSlideOptions, color: theme.color });
      slide.addImage({ data: content.contentData.plotOptions?.plotImage, ...imagePositionSlideOptions });
    }
  });

  pres.writeFile({ fileName: `${titleSlide?.data}.pptx` });
};
